import React from "react";
import * as styles from "./ourTeam.module.scss";
import JSONData from "../../../content/aboutUsOurTeamData.json"
import {graphql, Link, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {Col, Row} from "react-bootstrap";
import Cta from "../cta/cta";

export default function OurTeam() {

    const images = useStaticQuery(graphql`{
    images: allFile(filter: {relativeDirectory: {eq: "about-us/our-team"}}, sort: {fields: name}) {
        edges {
            node {
                childImageSharp{
                    gatsbyImageData
                }
            }
        }
    }}`);

    return <div>
        <div className={styles.title}>
            {JSONData.sectionTitle}
        </div>
        <div className={styles.header}>
            {JSONData.header}
        </div>
        <Row className={styles.portraitsWrapper}>
            {JSONData.team.map((data: any, idx: number) => <Portrait
                    key={idx}
                    linkedIn={data.linkedIn}
                    image={images.images.edges[idx].node.childImageSharp.gatsbyImageData}
                    firstName={data.firstName}
                    lastName={data.lastName}
                />
            )}
        </Row>
        <Cta
            title="Want to join our team?"
            buttonText="See our open roles"
            buttonTarget="/careers"
        />
    </div>
}

function Portrait(props: { image: any, firstName: string, lastName: string, linkedIn: string }) {
    return <Col xs={{span: 6}} lg={{span: 3}} className={styles.portraitContainer}>
        <div className={styles.portraitWrapper}>
            <GatsbyImage className={styles.portraitImage} image={props.image} alt={"person"}/>
            <div className={styles.nameWrapper}>
                <div className={styles.name}>{props.firstName} {props.lastName}</div>
                {
                    props.linkedIn && (
                        <a href={props.linkedIn} target="_blank" className={styles.linkedIn}>
                            in
                        </a>
                    )
                }
            </div>
        </div>
    </Col>
}
